@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");


body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow-y: scroll;

  background-repeat: no-repeat;
  background-attachment: fixed; /* Assure que le fond reste fixe */
  color:  var(--text-color-light);
  transition: background-color 0.5s ease, color 0.5s ease;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  --background-color-light: #ffffff;
  --text-color-light: #333333;
  --line-color-light: #333333;
}

/* styles.css */
.success-background {
  background: linear-gradient(15deg, #ffffff 0%, #fffdfd 40%, #ffeb3b 90%);
  min-height: 100vh; /* Pour s'assurer que le background couvre toute la hauteur */
}

/*******scroll***********/

/************Navmible 1279px****/

@media (max-width: 1279px) {
  .nav-links {
    display: none; /* Masquer les liens pour les tailles d'écran < 1279px */
  }

  .mobile-nav-links {
    display: none; /* Par défaut, masqué */
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .mobile-nav-links.open {
    display: flex; /* Afficher le menu mobile */
  }

  .menu-burger {
    display: flex;
  }
}

@media (min-width: 1280px) {
  .menu-burger {
    display: none;
  }
}

.relative:hover .line {
  transform: scaleX(1);
  transition-duration: 300ms;
}

.line {
  transform-origin: 0 50%;
  transform: scaleX(0);
  transition: transform 300ms;
}

.navbar {
  position: relative;
  z-index: 1000;
}

.line {
  background-color: var(--line-color-light);
}

/*****Forms**********/

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(75, 0, 130, 0.3);
  border-radius: 5px;
  margin-bottom: 15px;
  outline: none;
  font-size: 16px;
  transition: all 0.1s;
  cursor: pointer;
}

input:focus {
  border: 3px solid #273c75;
  font-family: "source-code-pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*********Forms**********/

.input-containers {
  position: relative;
  margin-bottom: 40px; /* Augmentez cette valeur pour ajouter de l'espace entre les blocs */
}

.input_contact {
  width: 100%;
  padding: 15px; /* Augmentez cette valeur pour rendre les inputs plus larges */
  box-sizing: border-box;
  border: 2px solid #273c75;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  transition: border-color 0.3s;
}

.input_contact:focus {
  border-color: #273c75;
}

.input-containers:focus-within label {
  transform: translateY(-20px);
  font-size: 10px;
  color: #273c75;
  transition: 0.3s;
}

.label_contact {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 5px;
  margin-left: -5px;
  font-size: 12px;
  color: #273c75;
}

.btn2 {
  width: 100%;
  padding: 10px;
  background-image: linear-gradient(135deg, #7f00ff, #e100ff);
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-image 0.3s;
}

.btn2:hover {
  background-image: linear-gradient(-135deg, #7f00ff, #e100ff);
}


.input_contact::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.5);
}
/****Icon Animate*********/

.social-icon {
  transition: transform 0.3s;
}

a:hover .social-icon {
  transform: translateY(-5px);
}

/**********loader****/

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: spinner-e04l1k 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #474bff #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 8.9px;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}

.mobile-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  z-index: 10;
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
}

.icon-light {
  color: #000000; /* Couleur des icônes en mode clair */
}

.content-margin-mobile {
  margin-bottom: 60px;
}

.darkmode-wrapper {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.enlarged-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.slider-container {
  padding-left: 15px;
  padding-right: 15px;
}

/***loaderForm*/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: spinner-e04l1k 1s infinite linear;
}

/*****Media queries*****/

.footer-container {
  min-height: 22vh;
}

@media (min-width: 768px) {
  .footer-container {
    min-height: 0;
  }
}

.hide-on-mobile {
  display: inline;
}

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}

.footer-container {
  border-radius: 20px;
}

.roadmap-circle {
  min-width: 16px; /* Largeur minimale pour éviter la déformation */
  min-height: 16px; /* Hauteur minimale pour éviter la déformation */
}

@media (max-width: 767px) {
  .footer-text {
    font-size: 0.875rem; /* Ajustez la taille de la police comme vous le souhaitez */
  }

  @media (max-width: 767px) {
    .footer-wrapper {
      height: 50vh;
      border-radius: 20px;
      padding: 1rem;
    }
  }

  @media (max-width: 767px) {
    .footer-container {
      padding: 1rem;
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }

    .footer-links a {
      margin: 0.5rem;
      font-size: 1rem;
    }

    .social-icons {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    .follow-us {
      display: none;
    }
  }

  .icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000; /* changez la couleur de la bordure selon votre préférence */
  }

  .mx-4 {
    margin-left: 2rem !important; /* Ajustez la valeur pour augmenter ou diminuer l'espace */
    margin-right: 2rem !important; /* Ajustez la valeur pour augmenter ou diminuer l'espace */
  }

  .progress-bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 4px;
    z-index: 3;
  }

  /******mouse***********/

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /*******Arrow**********/

  .scrollTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
    color: #6200ea;
    text-align: center;
    line-height: 80px;
    font-size: 40px;
    cursor: pointer;
    z-index: 1000;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    .scrollTop {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
    }
  }

  @media (max-width: 768px) {
    .scrollTop {
      display: none;
    }
  }

  /***************part foyer*************/

  .part-foyer {
    height: auto;
    overflow-y: auto;
  }
}

/***********TEXT BLOC1 ACCUEIL******************* */

@media (max-width: 1600px) {
  .bloc1-accueil {
    font-size: 1.5rem !important;
  }
}

/***********TEXT BLOC2 ACCUEIL******************* */

@media (max-width: 1892px) {
  .bloc2-accueil {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 1800px) and (max-width: 2000px) {
  .bloc2-accueil {
    margin-top: 8rem;
  }
}

@media (max-width: 1440px) {
  .bloc2-accueil {
    font-size: 1rem !important;
  }
}

@media (max-width: 764px) {
  .bloc2-accueil {
    margin-top: 2rem;
  }
}

/******************************************************/

.collapse-content {
  transition: height 0.3s ease;
  overflow: hidden;
}

.relative-z-10 {
  position: relative;
  z-index: 10;
}

.mb-8 {
  margin-bottom: 2rem;
}

/******************Navbar******************************************/
.rounded-hover-container {
  display: flex;
  position: relative;
}

.rounded-hover {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
}

#hover-bg {
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  background-color: #1d63ff;
  border-radius: 20px;
  pointer-events: none;
  z-index: 0;
  transform: translateY(-50%);
  transition: left 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.rounded-hover:hover {
  color: white;
  background-color: #1d63ff;
}

.rounded-hover:hover #hover-bg {
  left: 0;
  width: 100%;
  opacity: 1;
}

@media (max-width: 1280px) {
  .rounded-hover-container {
    display: none;
  }
}

/*********************  Responsive BARNAV*****************************/

/* Ajoutez ces styles pour la barre de navigation sur mobile */
#navbar.mobile-nav {
  background-color: rgba(255, 255, 255, 0.95); /* Fond blanc légèrement transparent */
  backdrop-filter: blur(10px); /* Effet de flou */
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Bordure subtile */
  color: #1D63FF;
}

/* Style pour le menu mobile ouvert */
.mobile-menu-open {
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.05);
}

.mobile-nav button {
  color: #4A5568; /* Gris foncé pour les boutons */
}

.mobile-nav button:hover {
  color: #1D63FF; /* Bleu au survol */
}

/* Ajouter un effet de transition doux */
.mobile-nav button {
  transition: color 0.2s ease;
}

/* Style pour l'élément actif dans la navigation */
.mobile-nav button.active {
  color: #1D63FF;
  font-weight: 500;
}

/****************asterix *********************/

.asterisk {
  color: red;
  margin-left: 4px;
}

/* Ajoutez ou modifiez ces styles */
#root {
  background-color: white;
}

/* Si vous aviez des styles avec un fond jaune, remplacez-les par : */
.bg-yellow-50, 
.bg-yellow-100, 
.bg-yellow-200 {
  background-color: white !important;
}

/* Ajoutez ces styles à votre fichier CSS */

.form-section {
  transition: all 0.3s ease;
}

.form-section:hover {
  transform: translateY(-2px);
}

.input-container input:focus,
.input-container select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2);
}

.shimmer-button {
  background-size: 200% 200%;
  animation: shimmer 2s linear infinite;
}

@keyframes shimmer {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

.modal-enter {
  opacity: 0;
  transform: scale(0.95);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}

@keyframes gradient-xy {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.animate-gradient-xy {
  background-size: 200% 200%;
  animation: gradient-xy 15s ease infinite;
  transition: background-image 0.5s ease;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

/* Ajoutez ces styles pour assurer que le conteneur s'adapte correctement */
.step-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Permet le défilement vertical */
  padding-bottom: 2rem; /* Ajoute de l'espace en bas */
  /* Styles pour la barre de défilement */
  scrollbar-width: thin;
  scrollbar-color: #1D63FF rgba(0, 0, 0, 0.1);
}

/* Pour les navigateurs Webkit (Chrome, Safari, etc.) */
.step-container::-webkit-scrollbar {
  width: 8px;
}

.step-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.step-container::-webkit-scrollbar-thumb {
  background: #1D63FF;
  border-radius: 10px;
  transition: background 0.3s ease;
}

.step-container::-webkit-scrollbar-thumb:hover {
  background: #144cc7;
}

/* Ajustement du conteneur du formulaire */
.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: auto;
  min-height: 100%;
}

@keyframes subtle-gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.animate-gradient-xy {
  background-size: 200% 200%;
  animation: subtle-gradient 15s ease infinite;
}


/* Ajoutez ces styles */
.bg-gradient-custom {
  background: radial-gradient(
    circle at top left,
    rgba(253, 224, 71, 0.2),
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
