
.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Pour ajouter un petit espace entre les éléments */
}


.loader {
  background-color: #007bff; /* Utiliser un bleu vif */
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform-origin: center;
  transition: 1s;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px #0056b3, /* Nuance de bleu plus sombre */
              inset 5px 5px 12px rgba(0, 91, 187, 0.8), /* Ajustement de la couleur */
              inset 8px 8px 1px rgba(0, 123, 255, 0.7), /* Ajustement de la couleur */
              0px 0px 1px rgba(0, 123, 255, 0.6); /* Ajustement de la couleur */
  animation: 1.2s linear infinite 0s running first682;
  z-index: 1001;
}



.loader div {
  width: inherit;
  height: inherit;
  position: absolute;
}

#first {
  transform: rotate(90deg)
}

#first::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width)/2);
  width: 1em;
  height: 1em;
  background-color: #3399ff; /* Utiliser un bleu légèrement plus clair */
  box-shadow: inset 5px 5px 10px #007bff, /* Nuance de bleu plus sombre */
              0px 0px 2px white;
  border-radius: 50px;
  animation: 0.8s ease-in 0s infinite running jump2;
}

#second {
  transform: rotate(90deg);
}

#second::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width)/2);
  width: 1em;
  height: 1em;
  background-color: #3399ff; /* Utiliser un bleu légèrement plus clair */
  box-shadow: inset 5px 5px 10px #007bff, /* Nuance de bleu plus sombre */
              0px 0px 2px white;
  border-radius: 50px;
  animation: 1.5s ease-in 0s infinite running jump2;
}

#third {
  transform: rotate(90deg);
}

#third::before {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width)/2);
  width: 1em;
  height: 1em;
  background-color: #3399ff; /* Utiliser un bleu légèrement plus clair */
  box-shadow: inset 5px 5px 10px #007bff, /* Nuance de bleu plus sombre */
              0px 0px 2px white;
  border-radius: 50px;
  animation: 1.6s ease-in 0s infinite running jump2;
}

.loader::after {
  --width: 1em;
  --height: 1em;
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - var(--width)/2);
  width: 1em;
  height: 1em;
  background-color: #3399ff; /* Utiliser un bleu légèrement plus clair */
  box-shadow: inset 5px 5px 10px #007bff, /* Nuance de bleu plus sombre */
              0px 0px 2px white;
  border-radius: 50px;
  animation: 1.2s ease-in 1s alternate infinite running jump2;
}


@keyframes first682 {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(0deg);
    transform: rotate(90deg)
  }

  50% {
    transform: rotate(90deg);
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(180deg);
    transform: rotate(270deg)
  }

  100% {
    transform: rotate(270deg);
    transform: rotate(360deg);
  }
}

@keyframes jump2 {
  0% {
    top: 100%;
  }

  25% {
    top: 230%
  }

  50% {
    top: 100%;
  }

  75% {
    height: 0.6em
  }

  100% {
    height: 1em
  }
}
.percentage {
  font-size: 18px;
  position: absolute; 
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 999;
}

.loading-text {
  margin-top: 30%;
  font-size: 18px;
  position: relative;
 
}

.loading-overlay {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


@media (max-width: 600px) {
  .loading-text {
      margin-top: 40%;
      font-size: 16px;
  }

  .percentage {
      font-size: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
  }
}


